import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ResetPassword from "../../../pages/auth/resetPassword/resetPassword";

import Logo from "../../../assets/images/logo.png";
import ChangePassword from "../../../assets/images/change-password-icon.png";
import Logout from "../../../assets/images/logout-icon.png";

import { clearLocalStorage } from "../../../utils/utils";

import "./header.scss";

interface LogoutItemProps {
  src: string;
  title: string;
  width: number;
  height: number;
  handleOnClick?: () => void;
}

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<string>("Subscription");
  const [showModal, setShowModal] = useState<boolean>(false);

  const navItems = [
    { name: "Subscription", path: "/subscription" },
    { name: "Package & Backup", path: "/package" },
  ];

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const selectTab = () => {
    const path = location.pathname;
    const formattedPath = path.slice(1);
    switch (formattedPath) {
      case "subscription":
        setTab("Subscription");
        break;
      case "package":
        setTab("Package & Backup");
        break;
    }
  };

  const handleSignOut = () => {
    clearLocalStorage();
    navigate("/login");
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (!(event.target as Element)?.closest(".forgotPasswordPageContainer")) {
      closeModal();
    }
  };

  useEffect(() => {
    selectTab();
  }, [location]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  const ActionItem: React.FC<LogoutItemProps> = ({
    src,
    title,
    width,
    height,
    handleOnClick,
  }) => (
    <li className="logout" onClick={handleOnClick}>
      <img title={title} src={src} alt={title} width={width} height={height} />
      <span>{title}</span>
    </li>
  );

  return (
    <>
      <header>
        <div className="logo-container">
          <img src={Logo} alt="myte-logo" width="95" height="38" />
        </div>
        <div className="tab-container">
          <ul>
            {navItems.map((item, index) => (
              <li
                key={index}
                className={item?.name === tab ? "select" : ""}
                onClick={() => {
                  if (item.path) {
                    tab !== item?.name && navigate(item.path);
                  }
                }}
              >
                {item?.name}
              </li>
            ))}
          </ul>
          <div>
            <ul className="logout-wrap">
              <ActionItem
                title="Change Password"
                src={ChangePassword}
                width={20}
                height={20}
                handleOnClick={openModal}
              />
              <ActionItem
                title="Logout"
                src={Logout}
                width={20}
                height={20}
                handleOnClick={handleSignOut}
              />
            </ul>
          </div>
        </div>
      </header>
      {showModal && (
        <div className="change-password-container">
          <ResetPassword isChangePassword={true} />
        </div>
      )}
    </>
  );
};

export default Header;
