import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Header from "./layout/header/Header";

import { getLocalStorage } from "../utils/utils";

const ProtectedRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);
  const token: string | null = getLocalStorage("access_token");

  const ContentLayout = () => {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  };

  return token ? <ContentLayout /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
