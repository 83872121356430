import "./EmptyPlaceholder.scss";

const EmptyPlaceholder = (props: { title: string; children?: any }) => {
  return (
    <div className="empty-placehoder">
      <div className="placeholder-container">
        <p className="placeholder-title">{props.title}</p>
        {props.children && <div className="placeholder-content">{props.children}</div>}
      </div>
    </div>
  );
};

export default EmptyPlaceholder;
