import { useEffect, useRef, useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// import PaymentForm from "../../components/paymentform/paymentform";
import { Button } from "../../components/button/button";
import Loader from "../../components/loader/loader";

import { setLocalStorage } from "../../utils/utils";
import {
  VerifySubscription,
  CreatePaymentIntent,
  CreateBillingSession,
} from "../../api-config/services/Subscription.service";

import "./subscription.scss";

interface CustomButton extends HTMLButtonElement {
  setLoader: () => void;
}

// const public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";
// const stripePromise = loadStripe(public_key);

const Subscription = () => {
  const [paymentIntent, setPaymentIntent] = useState<string | null>(null);
  const [subscribed, setSubscribed] = useState<string | null>(null);
  const [loading, setLoading] = useState({
    isLoader: false,
    isPaymentProcessing: false,
  });

  const btnRef = useRef<CustomButton | null>(null);

  const isInactive = subscribed === "inactive";

  const generatePI = async () => {
    CreatePaymentIntent()
      .then((res) => {
        setPaymentIntent(res?.data?.client_secret);
        setLoading({ ...loading, isLoader: false });
      })
      .catch((err) => {
        console.log(err);
        setLoading({ ...loading, isLoader: false });
      });
  };

  const getSubscriptionStatus = async () => {
    setLoading({ ...loading, isLoader: true });
    VerifySubscription()
      .then((res) => {
        const isSubscribed =
          res?.data?.status === "active" || res?.data?.status === "trialing";
        setLocalStorage("isSubscribed", isSubscribed.toString());
        setSubscribed(res?.data?.status);
        setLoading({ ...loading, isLoader: false });
        res?.data?.status === "inactive" && generatePI();
      })
      .catch((err) => {
        console.log(err);
        setLoading({ ...loading, isLoader: false });
      });
  };

  const generateBillingSession = async () => {
    btnRef.current?.setLoader();
    CreateBillingSession()
      .then((res) => {
        window.open(res?.data?.url, "_blank");
        btnRef.current?.setLoader();
      })
      .catch((err) => {
        console.log(err);
        btnRef.current?.setLoader();
      });
  };

  useEffect(() => {
    getSubscriptionStatus();
  }, []);

  const Title = () => (
    <div className="title">
      {isInactive ? "Subscribe to Myte Social" : "Your Subscription is Active"}
    </div>
  );

  const Description = () => (
    <p>
      {isInactive
        ? "Unlock all features with a subscription and enjoy a 7-day free trial."
        : "Thank you for subscribing! Your payment method has been verified successfully."}
    </p>
  );

  const ManageSubscription = () => (
    <div className="manage-subscription-container">
      <p>Manage your subscription settings and preferences below.</p>
      <Button
        ref={btnRef}
        title="Manage Subscription"
        onClick={generateBillingSession}
      />
    </div>
  );

  const PaymentElements = () => (
    <div className="payment-section">
      <h4>Select Your Plan</h4>
      <p>Choose a subscription plan that fits your needs and unlock the full potential of Myte Social.</p>
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        customer-session-client-secret={paymentIntent}
      ></stripe-pricing-table>
    </div>
  );

  const SubscriptionContent = () => {
    if (subscribed === null) return null;
    return (
      <>
        <div className="header">
          <Title />
          <Description />
        </div>
        {!isInactive ? (
          <ManageSubscription />
        ) : (
          paymentIntent && <PaymentElements />
        )}
      </>
    );
  };

  return (
    <>
      {loading.isLoader ? (
        <Loader showLogo={false} />
      ) : (
        <div
          className={`subscription-container transparent-scrollbar ${
            !isInactive ? "align-center" : ""
          }`}
        >
          <div
            className={`subscription-inner ${!isInactive ? "max-width" : ""}`}
          >
            <SubscriptionContent />
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
