import "./verifyEmail.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader/loader";
import { setLocalStorage } from "../../../utils/utils";
import { VerifyUserEmail } from "../../../api-config/services/Auth.service";
import { GetUserDetails } from "../../../api-config/services/User.service";

interface Response {
  data: {
    access_token: string;
  };
  message?: string;
}

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      setErrorMessage("Token is missing or invalid.");
      setLoading(false);
      setTimeout(() => navigate("/login"), 3000);
      return;
    }

    const verifyEmail = async (token: string) => {
      try {
        const response = await VerifyUserEmail(token); // Verify email API call
        const resData = response as Response;

        if (resData?.data?.access_token) {
          setLocalStorage("access_token", resData.data.access_token); // Store access token

          const userVerified = await GetUserDetails(); // Fetch user details to ensure verification
          if (userVerified) {
            setIsVerified(true); // Set verified state
            setTimeout(() => navigate("/subscription"), 5000);
          } else {
            throw new Error("Failed to fetch user details.");
          }
        } else {
          throw new Error("Invalid response from server.");
        }
      } catch (error) {
        setErrorMessage("Email verification failed. Please try again.");
        setTimeout(() => navigate("/login"), 3000);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail(token); // Execute verification function
  }, [token, navigate]);

  if (loading) return <Loader />;

  return (
    <div className="verification-container">
      {isVerified ? (
        <div className="success-message">
          <h2>Email Verified Successfully!</h2>
          <p>You will be redirected to your subscription page shortly.</p>
        </div>
      ) : (
        <div className="error-message">
          <h2>Verification Failed</h2>
          <p>{errorMessage}</p>
          <p>You will be redirected to the login page shortly.</p>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
