import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import BackgroundImage from "../../assets/images/background.jpg";

import "./outerLayout.scss";

const OuterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);
  return (
    <div className="outerLayoutContainer">
      <div className="backgroundContainer">
        <img src={BackgroundImage} alt="" width={"100%"} height={"100%"} />
      </div>
      <div className="outletContainer">
        <Outlet />
      </div>
    </div>
  );
};

export default OuterLayout;
