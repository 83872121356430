import { BrowserRouter, Routes, Route } from "react-router-dom";

import OuterLayout from "./outerLayout/outerLayout";
import ProtectedRoutes from "./ProtectedLayout";

import Login from "../pages/auth/login/login";
import SignUp from "../pages/auth/signUp/signUp";
import ForgotPassword from "../pages/auth/forgotPassword/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword/resetPassword";
import VerifyEmail from "../pages/auth/verifyEmail/verifyEmail";
import Subscription from "../pages/subscription/subscription";
import PackageAndBackup from "../pages/package/package";

const Template = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/package" element={<PackageAndBackup />} />
        </Route>

        {/* Public Routes */}
        <Route path="/" element={<OuterLayout />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Template;
