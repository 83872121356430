import { toast } from "react-toastify";

import { ToasterWarningIcon } from "../../core/icons/toaster-icons/ToasterWarningIcon";
import { ToasterErrorIcon } from "../../core/icons/toaster-icons/ToasterErrorIcon";
import { ToasterSuccessIcon } from "../../core/icons/toaster-icons/ToasterSuccessIcon";

import "./Toastify.scss";

export const showToastSuccessMessage = (message: any) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: ToasterSuccessIcon,
  });
};

export const showToastErrorMessage = (message: any) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: ToasterErrorIcon,
  });
};

export const showToastWarningMessage = (message: any) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
    icon: ToasterWarningIcon,
  });
};
