import { ToastContainer } from "react-toastify";

import Template from "./template/template";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function App() {
  return (
    <>
      <ToastContainer autoClose={5000} />
      <div className="App">
        <Template />
      </div>
    </>
  );
}

export default App;
