import "./ToasterErrorIcon.scss";

export const ToasterErrorIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#E22A34" />
      <g clipPath="url(#clip0_6454_6608)">
        <path
          d="M27.2843 12.7155C26.4518 11.8829 25.203 11.8829 24.3705 12.7155L19.9998 17.0862L15.629 12.7155C14.7965 11.8829 13.5477 11.8829 12.7152 12.7155C11.8827 13.548 11.8827 14.7968 12.7152 15.6293L17.0859 20L12.7152 24.3707C11.8827 25.2033 11.8827 26.452 12.7152 27.2846C13.1315 27.7008 13.7559 27.909 14.1721 27.909C14.5884 27.909 15.2128 27.7008 15.629 27.2846L19.9998 22.9138L24.3705 27.2846C24.7868 27.7008 25.4111 27.909 25.8274 27.909C26.2437 27.909 26.8681 27.7008 27.2843 27.2846C28.1168 26.452 28.1168 25.2033 27.2843 24.3707L22.9136 20L27.2843 15.6293C28.1168 14.7968 28.1168 13.548 27.2843 12.7155Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6454_6608">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
