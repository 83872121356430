import { apiEndpoints, makeRequest } from "../api-config";

interface Response {
  data: {
    [key: string]: string;
  };
  message?: string;
}

export const VerifySubscription = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.subscription.verifySubscription}`;
  return makeRequest("get", url, null, false) as Promise<Response>;
};

export const CreatePaymentIntent = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.subscription.createPaymentIntent}`;
  return makeRequest("post", url, null, false) as Promise<Response>;
};

export const CreateBillingSession = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.subscription.createBillingSession}`;
  return makeRequest("get", url, null, false) as Promise<Response>;
};
