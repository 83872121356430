import { apiEndpoints, makeRequest } from "../../api-config/api-config";

interface UserCredentials {
  email: string;
  password?: string;
  full_name?: string;
}
export interface ResetPasswordCredentials {
  token: string;
  new_password: string;
}

export const Signup = async (data: UserCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.signup}`;
  return makeRequest("post", url, data);
};

export const Signin = async (data: UserCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.signin}`;
  return makeRequest("post", url, data);
};

export const ResendVerificationEmail = async (data: UserCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.resendVerificationEmail}`;
  return makeRequest("post", url, data);
};

export const VerifyUserEmail = async (token: string) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.verifyEmail}?token=${token}`;
  return makeRequest("get", url);
};

export const ForgotPassword = async (data: UserCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.forgotPassword}`;
  return makeRequest("post", url, data);
};

export const ResetPassword = async (data: ResetPasswordCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.auth.resetPassword}`;
  return makeRequest("post", url, data);
};
