import axios from "axios";
import { getLocalStorage } from "../utils/utils";

export const http = {
  post: axios.post,
  get: axios.get,
  delete: axios.delete,
  patch: axios.patch,
  put: axios.put,
};

export const setupInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getLocalStorage("access_token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete config.headers.Authorization;
      }
      return config;
    },
    (error) => {
      console.log("error: ", error);
      return Promise.reject(error);
    }
  );
};
